import React from "react";

import { Container } from "semantic-ui-react";

import LayoutInner from "../components/layout/layout-inner";
import SEO from "../components/seo/seo";
import BodyText from "../components/body-text/body-text";
import SecondaryNavBar from "../components/secondary-nav-bar/secondary-nav-bar";
import { CAREERS_EMAIL } from "../constants";

const Page = () => (
  <LayoutInner>
    <SEO title="Application Complete" keywords={[`rocksteady`]} />
    <SecondaryNavBar
      product="generic"
      title="Application Complete"
      titlePath="/application-complete/"
      titleActive={true}
      links={[]}
    />
    <Container>
      <BodyText>
        <p>
          Thank you for submitting your application form. We will review your
          application and be in touch shortly.
        </p>
        <p>
          Please email{' '}
          <a href={`mailto:${CAREERS_EMAIL}`}>
            {CAREERS_EMAIL}
          </a>
          {' '}for any further information
        </p>
        <img
          height={1}
          width={1}
          border={0}
          src="//conv.indeed.com/pagead/conv/8610658054459872/?script=0"
          alt="indeed tracking"
        />
      </BodyText>
    </Container>
  </LayoutInner>
);

export default Page;
